<template>
    <div class="m-member">
        <div class="menber-wrap">
            <div class="menber-title">
                <div class="menber-text">会员付费升级</div>
                <div class="menber-p">
                    <span @click="goTo('/login')">登录</span>
                    <span>|</span>
                    <span @click="goTo('/register')">注册</span>
                </div>
            </div>
            <div class="menber">
                <div class="menber-left">
                    <div class="code-wrap">
                        <div class="code">
                            <div class="code-img"><img  :src="list.pay_qr_url" alt=""></div>
                            <div class="code-sys">
                                <div class="code-sys-img"><img src="../assets/images/sys.png" alt=""></div>
                                <p>扫一扫成为付费会员<br>获取更多知识浏览权限</p>
                            </div>
                            
                        </div>
                        <div class="code-title">扫码添加官方微信<br>完成会员升级</div>                        
                    </div>
                </div>
                <div class="menber-right">
                    <div class="wenhao">
                        <img src="../assets/images/wenhao.png" alt="">
                        <div class="wenhao-bt" @click="goTo('statement',{id:1})">会员等级说明</div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    import { getwes } from '../api/index'
    export default {
        data() {
            return {
                list:[],                
            }
        },
        created() {
            this.getwes()
        },
        methods: {
            goTo(url,query) {
                if (url=='statement') {
                    this.$router.push({ 
                        path: url ,
                        query
                    });
                }else{
                    this.$router.replace({ 
                        path: url ,
                        query
                    });
                }
                
            },
            getwes(){
                getwes().then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.list=res.data;
                    }
                })
            }
        },
    }
</script>

<style lang="less" scoped>
.m-member{
    background: #EFF0F1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .menber-wrap{
        width: 1100px;
        .menber-title{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 20px;
            .menber-text{font-size: 22px;}
            .menber-p{
                font-size: 18px;color: #FD5F08;cursor: pointer;
                span:nth-child(2){margin: 0 10px;}
            }
        }
        .menber{
            height: 600px;
            display: flex;
            .menber-left{
                width: 82%;background: #fff;display: flex;align-items: center;justify-content: center;
                .code{
                    border: 8px solid #E7E7E7;padding:7px;
                    .code-img{
                        width: 230px;
                        img{width: 100%;}
                    }
                    .code-sys{
                        display: flex;
                        align-items: center;
                        margin-top: 10px;
                        .code-sys-img{
                            width: 60px;
                            img{width: 60px;}
                        }
                        p{
                            color: #757575;
                            font-size: 16px;
                            line-height: 26px;
                        }
                    }
                }
                .code-title{
                    font-size: 24px;
                    color: #FD5F08;
                    text-align: center;
                    line-height: 40px;
                    padding-top: 20px;
                }

            }
            .menber-right{
                width:18%;background: #E6E6E6;display: flex;align-items: center;justify-content: center;
                .wenhao{
                     width: 100%;
                    img{width: 43px;height:auto;margin: auto;display: flex;}
                    .wenhao-bt{
                        width: 70%;
                        height: 38px;
                        background: #00AAF0;
                        text-align: center;
                        line-height: 38px;
                        border-radius: 5px;
                        font-size: 16px;
                        color: #fff;
                        margin: 20px auto 0 auto;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

</style>